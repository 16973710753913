import { defineStore } from "pinia";
import {
  BarChartData,
  LineChartData,
  RealTimeData,
  ChartDataZip,
  GraphDataZip,
  CardSearchZip,
  EsgDataZip,
  ReducEsgDataZip,
  RfidDataZip,
} from "../interface/real.time.data";
import _ from "lodash";
import { Device } from "@/interface/device";
import { getCookie } from "@/service/local/cookies.service";
import { tokenRefresh } from "@/service/account.service";
import { ref } from "vue";

const realTimeData: RealTimeData[] = [];
const lineChartData: LineChartData[] = [];
const barChartData: BarChartData[][] = [];
const device: Device[] = [];
// 추가본
const chartData: ChartDataZip[] = [];
const graphData: GraphDataZip[] = [];
const cardData: CardSearchZip[] = [];
const esgData: EsgDataZip[] = [];
const reductionEsgData: ReducEsgDataZip[] = [];
const rfidData: RfidDataZip[] = [];
// 현재 장비값 저장할 스토어

export const userUseStore = defineStore("user", {
  state: () => ({
    CHART_COUNT: 60,

    realTimeData: realTimeData,
    barChartData: barChartData,
    lineChartData: lineChartData,
    deviceList: device,
    // 추가본
    chartData: chartData,
    graphData: graphData,
    cardData: cardData,
    esgData: esgData,
    reductionEsgData: reductionEsgData,
    rfidData: rfidData,

    dashboardChart: [
      { status: "Run", value: 0, color: "#78C350" },
      { status: "Stop", value: 0, color: "#f8f9fa" },
      { status: "Error", value: 0, color: "#f06292" },
    ],
    isLoading: false,
  }),

  actions: {
    authPass() {
      const rfToken = getCookie("refresh_T");
      const acToken = getCookie("access_T");
      if (!rfToken && !acToken) {
        return false;
      } else if (rfToken && !acToken) {
        tokenRefresh();
        return true;
      } else {
        return true;
      }
    },

    findMac(mac: string) {
      const idx = _.findIndex(this.realTimeData, { mac_addr: mac });
      return idx;
    },

    async loadingChange(flag: number) {
      if (flag === 0) {
        this.isLoading = true;
      } else if (flag === 1) {
        this.isLoading = false;
      }
    },
  },
});
