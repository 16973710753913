import axios from "axios";
import { headerClear, tokenRefresh } from "./account.service";
import { deleteCookie, getCookie } from "./local/cookies.service";
import _ from "lodash";
import router from "@/router";
import { ROU_NAME } from "@/router/router.name";

export const superAxios = axios.create({ baseURL: "api/", timeout: 5000 });

//요청 후
superAxios.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    if (
      err.response.status === 401 &&
      err.response.data.detail === "Token changed"
    ) {
      sessionStorage.clear();
      deleteCookie("access_T");
      deleteCookie("refresh_T");
      headerClear();
      alert("Duplicate login");
      router.push({ name: ROU_NAME.LOGIN });
    } else if (
      err.response.status === 401 &&
      err.response.data.detail === "Missing Authorization Header"
    ) {
      await tokenRefresh();
      return await superAxios.request(err.config);
    } else if (
      err.response.status === 422 &&
      err.response.data.detail === "Signature has expired"
    ) {
      err.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
        "access_T"
      )}`;
      await tokenRefresh();
      return await superAxios.request(err.config);
    } else {
      return Promise.reject(err);
    }
  }
);

//유저 리스트 갱신
export const userListAxios = async () => {
  try {
    const res = await superAxios.get("account/list");
    return res.data.user_list;
  } catch (err) {
    console.log("User List FAIL : ", err);
  }
};

//그룹 리스트 갱신
export const groupListAxios = async () => {
  try {
    const res = await superAxios.get("group/list");
    return res.data.group_list;
  } catch (err) {
    console.log("Group List FAIL : ", err);
  }
};

//유저 추가
export const signUpAxios = async (
  name: string,
  password: string,
  group: string,
  manager: string,
  permission: string,
  email: string
) => {
  try {
    await superAxios.get("account/insert", {
      params: {
        username: name,
        password: password,
        group: group,
        sub_group: manager,
        permission: permission,
        email: email,
      },
    });
  } catch (err) {
    console.log("User Insert FAIL : ", err);
  }
};

//그룹 추가
export const groupInsertAxios = async (group: string) => {
  try {
    await superAxios.get("group/insert", { params: { group: group } });
  } catch (err: any) {
    // eslint-disable-next-line
    console.log("Group Insert FAIL : ", err);
  }
};

//유저 삭제
export const userDeleteAxios = async (user: string) => {
  try {
    await superAxios.get("account/delete", { params: { username: user } });
    // eslint-disable-next-line
  } catch (err: any) {
    console.log("User Delete FAIL : ", err);
  }
};

//그룹 삭제
export const groupDeleteAxios = async (group: string) => {
  try {
    await superAxios.get("group/delete", { params: { group: group } });
  } catch (err: any) {
    console.log("Group Delete FAIL : ", err);
  }
};

//매니저 추가
export const subGroupInsertAxios = async (subGroup: string, group: string) => {
  try {
    await superAxios.get("sub_group/insert", {
      params: { sub_group: subGroup, group: group },
    });
  } catch (err: any) {
    console.log("Sub_Group Insert FAIL : ", err);
  }
};

//매니저 리스트 갱신
export const subGroupListAxios = async (group?: string) => {
  try {
    const res = await superAxios.get("sub_group/list");
    const managerList = res.data.sub_group_list;
    if (sessionStorage.getItem("permission") === "super") {
      if (group) {
        const groupMatch = _.filter(
          managerList,
          (list) => list.group === group
        );
        console.log(`${group} Manager List OK`);
        return groupMatch;
      } else {
        console.log(`Manager List OK`);
        return managerList;
      }
    } else {
      const groupMatch = _.filter(managerList, (list) => list.group === group);
      console.log(`${group} Manager List OK`);
      return groupMatch;
    }
  } catch (err) {
    console.log("Manager List FAIL : ", err);
  }
};

//매니저 삭제
export const subGroupDeleteAxios = async (subGroup: string) => {
  try {
    await superAxios.get("sub_group/delete", {
      params: { sub_group: subGroup },
    });
  } catch (err: any) {
    console.log("Sub_Group Delete FAIL : ", err);
  }
};

export const userRefactorAxios = async (
  name: string,
  group: string,
  manager: string,
  permission: string,
  email: string
) => {
  try {
    await superAxios.get("account/update", {
      params: {
        username: name,
        group: group,
        sub_group: manager,
        permission: permission,
        email: email,
      },
    });
  } catch (err) {
    console.log(err);
  }
};
