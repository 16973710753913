export const setCookie = function async(
	name: string,
	value: any,
	expiredDay: number
) {
	const expired = new Date()
	expired.setTime(expired.getTime() + expiredDay * 60 * 1000)

	document.cookie =
		name +
		'=' +
		encodeURIComponent(value) +
		';expires=' +
		expired.toUTCString() +
		';path=/'
}

export const getCookie = function (name: string) {
	let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
	return value ? decodeURIComponent(value[2]) : null
}

export const deleteCookie = function (name: string) {
	document.cookie = name + '=; expires=Thu, 01 Jan 1999 00:00:10 GMT;'
}
