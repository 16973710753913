import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import messages from "./i18n";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "ko",
  messages,
});

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(i18n);
app.use(BootstrapVue3);
app.config.globalProperties.axios = axios;
app.mount("#app");
