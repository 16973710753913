import router from "@/router";
import { ROU_NAME } from "@/router/router.name";
import axios from "axios";
import { headerClear, tokenRefresh } from "./account.service";
import { deleteCookie, getCookie } from "./local/cookies.service";

export const configAxios = axios.create({ baseURL: "api/", timeout: 5000 });

configAxios.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    if (
      err.response.status === 401 &&
      err.response.data.detail === "Token changed"
    ) {
      sessionStorage.clear();
      deleteCookie("access_T");
      deleteCookie("refresh_T");
      headerClear();
      alert("Duplicate login");
      router.push({ name: ROU_NAME.LOGIN });
    } else if (
      err.response.status === 401 &&
      err.response.data.detail === "Missing Authorization Header"
    ) {
      await tokenRefresh();
      return await configAxios.request(err.config);
    } else if (
      err.response.status === 422 &&
      err.response.data.detail === "Signature has expired"
    ) {
      err.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
        "access_T"
      )}`;
      await tokenRefresh();
      return await configAxios.request(err.config);
    } else {
      return Promise.reject(err);
    }
  }
);

export const configSettingAxios = async (
  mac: string,
  command: string,
  value: string
) => {
  try {
    const data = { command: command, value: value };
    const sendData = JSON.stringify(data);

    await configAxios.get("site/config", {
      params: {
        mac_addr: mac,
        config_data: sendData,
      },
    });
  } catch (err) {
    console.log("Setting Err : ", err);
  }
};

export const configRefreshAxios = async (mac: string, command: string) => {
  try {
    const data = { command: command, value: "Refresh" };
    const sendData = JSON.stringify(data);

    await configAxios.get("site/config", {
      params: {
        mac_addr: mac,
        config_data: sendData,
      },
    });
  } catch (err) {
    console.log("Refresh Err : ", err);
  }
};
