import axios from 'axios'
import router from '@/router'
import { ROU_NAME } from '@/router/router.name'
import { superAxios } from './super.service'
import { adminAxios } from './admin.service'
import { userAxios } from './user.service'
import { configAxios } from './config.service'
import { setCookie, getCookie, deleteCookie } from './local/cookies.service'

export const accountAxios = axios.create({ baseURL: 'api/', timeout: 5000 })

export const headerClear = () => {
	accountAxios.defaults.headers.common['Authorization'] = ''
	userAxios.defaults.headers.common['Authorization'] = ''
	adminAxios.defaults.headers.common['Authorization'] = ''
	superAxios.defaults.headers.common['Authorization'] = ''
	configAxios.defaults.headers.common['Authorization'] = ''
}

//요청 후
accountAxios.interceptors.response.use(
	async (res) => {
		return res
	},
	async (err) => {
		if (
			err.response.status === 401 &&
			err.response.data.detail === 'Token changed'
		) {
			sessionStorage.clear()
			deleteCookie('access_T')
			deleteCookie('refresh_T')
			headerClear()
			alert('Duplicate login')
			router.push({ name: ROU_NAME.LOGIN })
		} else if (
			err.response.status === 401 &&
			err.response.data.detail === 'Missing Authorization Header'
		) {
			await tokenRefresh()
			return await accountAxios.request(err.config)
		} else if (
			err.response.status === 422 &&
			err.response.data.detail === 'Signature has expired'
		) {
			err.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
				'access_T'
			)}`
			await tokenRefresh()
			return await accountAxios.request(err.config)
		} else {
			return Promise.reject(err)
		}
	}
)

//토큰 재발급
export const tokenRefresh = async () => {
	accountAxios.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
		'refresh_T'
	)}`
	try {
		const res = await accountAxios.post('refresh')

		const acToken = res.data.access_token
		const rfToken = res.data.refresh_token

		if (rfToken !== '') {
			setCookie('access_T', acToken, 10)
			setCookie('refresh_T', rfToken, 20161)

			accountAxios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${getCookie('access_T')}`
			superAxios.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
				'access_T'
			)}`
			adminAxios.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
				'access_T'
			)}`
			userAxios.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
				'access_T'
			)}`
			configAxios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${getCookie('access_T')}`
			console.log('Two Token Refresh')
		} else {
			setCookie('access_T', acToken, 10)

			accountAxios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${getCookie('access_T')}`
			superAxios.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
				'access_T'
			)}`
			adminAxios.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
				'access_T'
			)}`
			userAxios.defaults.headers.common['Authorization'] = `Bearer ${getCookie(
				'access_T'
			)}`
			configAxios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${getCookie('access_T')}`
			console.log('One Token Refresh')
		}
	} catch (err: any) {
		if (
			err.response.status === 422 &&
			err.response.data.detail === 'Not enough segments'
		) {
			router.push({ name: ROU_NAME.LOGIN })
			console.log(err)
		}
	}
}

//로그인
export const loginService = async (loginId: string, loginPwd: string) => {
	try {
		const res = await accountAxios.post('login', {
			username: loginId,
			password: loginPwd,
		})
		//로컬에 토큰 저장 후 각각의 헤더에 할당
		const acToken = res.data.access_token
		const rfToken = res.data.refresh_token

		setCookie('access_T', acToken, 10)
		setCookie('refresh_T', rfToken, 20161)

		accountAxios.defaults.headers.common['Authorization'] = `Bearer ${acToken}`
		superAxios.defaults.headers.common['Authorization'] = `Bearer ${acToken}`
		adminAxios.defaults.headers.common['Authorization'] = `Bearer ${acToken}`
		userAxios.defaults.headers.common['Authorization'] = `Bearer ${acToken}`
		configAxios.defaults.headers.common['Authorization'] = `Bearer ${acToken}`

		//세션에 유정 정보 저장 브라우저 종료시 삭제됨
		sessionStorage.setItem('username', res.data.username)
		sessionStorage.setItem('group', res.data.group)
		sessionStorage.setItem('manager', res.data.sub_group)
		sessionStorage.setItem('permission', res.data.permission)

		Promise.resolve(router.push({ name: ROU_NAME.DASHBOARD }))
	} catch (err: any) {
		if (err.response.status === 422) {
			if (err.response.data.detail === 'wrong username') {
				return err.response.data.detail
			} else if (err.response.data.detail === 'wrong password') {
				return err.response.data.detail
			} else {
				console.log(err)
			}
		} else {
			console.log(err)
		}
	}
}

//비밀번호 변경
export const passwordChangeAxios = async (password: string) => {
	try {
		await accountAxios.get('account/password_change', {
			params: { password: password },
		})
	} catch (err: any) {
		console.log('Password Change FAIL : ', err)
	}
}

//Id로 계정 정보 가져오기
export const accountInfoAxios = async (user: string) => {
	try {
		const res = await accountAxios.get('account/info', {
			params: { username: user },
		})
		sessionStorage.setItem('username', res.data.username)
		sessionStorage.setItem('group', res.data.group)
		sessionStorage.setItem('manager', res.data.sub_group)
		sessionStorage.setItem('permission', res.data.permission)
	} catch (err) {
		console.log('Account Info FAIL : ', err)
	}
}
