import { onActivated, onDeactivated, onUnmounted, ref } from 'vue'
import {
	setIntervalAsync,
	clearIntervalAsync,
} from 'set-interval-async/dynamic'

export default function useComponentLoop<T>(
	func: (...args: T[]) => Promise<any>,
	interval = 10,
	...args: T[]
) {
	const isRun = ref(false)
	let intervalId: any = null
	let isAutoPause = false

	const start = () => {
		if (!isRun.value) {
			isRun.value = true
			func(...args).finally(() => {
				intervalId = setIntervalAsync(func, interval, args)
			})
		}
	}

	const stop = () => {
		if (intervalId) clearIntervalAsync(intervalId)
		intervalId = null

		isRun.value = false
		isAutoPause = false
	}

	onUnmounted(() => {
		stop()
	})

	onActivated(() => {
		if (isAutoPause) {
			start()
			isAutoPause = false
		}
	})

	onDeactivated(() => {
		if (isRun.value) {
			stop()
			isAutoPause = true
		}
	})

	return {
		isRun,
		start,
		stop,
	}
}
