export const ROU_NAME = {
  LOGIN: "loginPage",

  USER: "user",
  ACCOUNT: "accountPage",
  DASHBOARD: "dashboardPage",
  DETAIL: "detailPage",
  // add start
  CARDSEARCH: "cardSearch",
  ESGSEARCH: "esgSearch",
  // add end
  SEARCH: "searchPage",

  ADMIN: "admin",
  ADMINPAGE: "adminPage",
  SETTING: "settingPage",

  SUPER: "super",
  SUPERPAGE: "superPage",
  DASHBOARD_CAM: "dashboardCam",
} as const;

export type ROU_NAME = typeof ROU_NAME[keyof typeof ROU_NAME];
