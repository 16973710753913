import _ from "lodash";

export const getCSV = async (name: string, data: any[], headers: string[]) => {
  const header = headers.map((header: string) => {
    return header.replace(/\s/g, "") + ",";
  });
  const rows = data.map((data: any) => {
    return _.values(data) + "\n";
  });
  const csv = header.concat("\n").concat(rows).join("");

  await downloadCSV(csv, name);
};

const downloadCSV = async (csv: any, filename: any) => {
  let csvFile;
  let downloadLink;

  //한글 처리를 해주기 위해 BOM 추가하기
  const BOM = "\uFEFF";
  csv = BOM + csv;

  csvFile = new Blob([csv], { type: "text/csv" });
  downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};
