import axios from "axios";
import _ from "lodash";
import { userUseStore } from "@/store/user";
import { headerClear, tokenRefresh } from "./account.service";
import { RealTimeData } from "@/interface/real.time.data";
import { deleteCookie, getCookie } from "./local/cookies.service";
import dayjs from "dayjs";
// 추가본
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
// 추가본
import router from "@/router";
import { ROU_NAME } from "@/router/router.name";
import { ref } from "vue";

// 데이터가 더 많은 양을 검색한다면, timeout 수정해야함
// export const userAxios = axios.create({ baseURL: "api/", timeout: 5000 });
// timeout : 600000ms(미리세컨드) = 10분

export const userAxios = axios.create({ baseURL: "api/", timeout: 600000 });

//요청 후
userAxios.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    if (
      err.response.status === 401 &&
      err.response.data.detail === "Token changed"
    ) {
      sessionStorage.clear();
      deleteCookie("access_T");
      deleteCookie("refresh_T");
      headerClear();
      alert("Duplicate login");
      router.push({ name: ROU_NAME.LOGIN });
    } else if (
      err.response.status === 401 &&
      err.response.data.detail === "Missing Authorization Header"
    ) {
      await tokenRefresh();
      return await userAxios.request(err.config);
    } else if (
      err.response.status === 422 &&
      err.response.data.detail === "Signature has expired"
    ) {
      await tokenRefresh();
      err.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
        "access_T"
      )}`;
      return await userAxios.request(err.config);
    } else {
      return Promise.reject(err);
    }
  }
);

//실시간 데이터 갱신
export const realTimeDataAxios = async () => {
  const groupSession = () => {
    if (sessionStorage.getItem("permission") === "super") {
      // return sessionStorage.getItem("permission");
      return;
    } else {
      return sessionStorage.getItem("group");
    }
  };
  const subGroupSession = () => {
    if (sessionStorage.getItem("permission") === "super") {
      return;
    } else if (sessionStorage.getItem("permission") === "admin") {
      return;
    } else if (sessionStorage.getItem("permission") === "manager") {
      return;
    }
  };
  const userSession = () => {
    if (sessionStorage.getItem("permission") === "super") {
      return;
    } else if (sessionStorage.getItem("permission") === "admin") {
      return;
    } else if (sessionStorage.getItem("permission") === "manager") {
      return;
    } else {
      return sessionStorage.getItem("username");
    }
  };
  try {
    const res = await userAxios.get("data/realtime", {
      params: {
        group: groupSession(),
        sub_group: subGroupSession(),
        username: userSession(),
      },
    });

    const userStore = userUseStore();
    const time = new Date();

    userStore.realTimeData = _.chain(res.data.datas)
      .reject((item) => item.MA === undefined)
      .map((item) => {
        return {
          mac_addr: item.MA,
          user_name: item.UN,
          group: item.GP,
          manager: item.SG,
          site_name: item.SN,
          update_time: item.UT,
          time: time.getTime(),
          model: item.MD,
          status: item.ST,
          cycle_status: item.CS,
          cycle_count: item.CT,
          main_switch_on: item.MS,
          alive_count: item.AC,
          weight: item.WT,
          tank_temperature: item.TT,
          tank_over_heating_detect_temperature: item.TO,
          remain_dry_time: item.RT,
          setup_dry_time: item.SD,
          remain_discharge_time: item.RCT,
          setup_discharge_time: item.SDC,
          remain_cooling_time: item.RC,
          setup_cooling_time: item.SCT,
          total_active_energy: item.TAE,
          total_active_power: item.TAP,
          operation_step: item.OS,
          oil_heater_1_temperature: item.O1,
          oil_heater_1_target_temperature: item.O1T,
          oil_heater_2_temperature: item.O2,
          oil_heater_2_target_temperature: item.O2T,
          air_heater_1_temperature: item.A1,
          air_heater_1_target_temperature: item.A1T,
          air_heater_2_temperature: item.A2,
          air_heater_2_target_temperature: item.A2T,
          discharge_heater_temperature: item.DT,
          discharge_heater_target_temperature: item.DTT,
          door_position: item.DP,
          lift_switch_on: item.LSO,
          lift_status: item.LS,
          lift_position: item.LP,
          error_input_weight_overload: item.EWO,
          error_oil_bi_metal: item.EOB,
          error_air_bi_metal: item.EAB,
          error_motor_overload: item.EMO,
          error_blower_pump_fan_overload: item.EBO,
          error_tank_temperature_over_heating: item.ETO,
          error_oil_heater_1_over_heating: item.EO1,
          error_oil_heater_2_over_heating: item.EO2,
          error_air_heater_1_over_heating: item.EA1,
          error_air_heater_2_over_heating: item.EA2,
          error_discharge_heater_over_heating: item.ED,
          error_emergency_stop: item.EE,
          error_lift_hydric_motor: item.EL,
          error_in_let_one_movement_error: item.NM1,
          error_in_let_two_movement_error: item.NM2,
          error_run_button_pressed_during_operation: item.RO,
          error_inlet_door_open_during_operation: item.IO,
          error_main_switch_turn_on: item.MSO,
          // 추가본
          card_id: item.ID,
          card_number: item.CN,
        };
      })
      .value();

    const chartData = userStore.realTimeData.map((real_data: RealTimeData) => {
      const data = {
        mac: real_data.mac_addr,
        time: real_data.time,
        weight: real_data.weight,
        temp: real_data.tank_temperature,
        power: real_data.total_active_power,
      };
      return data;
    });
    userStore.lineChartData = chartData;
    const barData = userStore.realTimeData.map((real_data: RealTimeData) => {
      const data = [
        {
          mac: real_data.mac_addr,
          name: "Oil Heater 1",
          value: real_data.oil_heater_1_temperature,
          color: "#9FD3FE",
        },
        {
          mac: real_data.mac_addr,
          name: "Oil Heater 2",
          value: real_data.oil_heater_2_temperature,
          color: "#87CEEB",
        },
        {
          mac: real_data.mac_addr,
          name: "Air Heater 1",
          value: real_data.air_heater_1_temperature,
          color: "#3090C7",
        },
        {
          mac: real_data.mac_addr,
          name: "Air Heater 2",
          value: real_data.air_heater_2_temperature,
          color: "#348CD4",
        },
        {
          mac: real_data.mac_addr,
          name: "Discharge Heater",
          value: real_data.discharge_heater_temperature,
          color: "#FD7E14",
        },
      ];
      return data;
    });
    userStore.barChartData = barData;

    console.log("RealTimeData");
  } catch (err) {
    console.log(err);
  }
};

//조회 데이터 요청
export const searchAxios = async (mac: string, start: string, end: string) => {
  try {
    const res = await userAxios.get("data/search", {
      params: {
        mac_addr: mac,
        start_time: start,
        end_time: end,
      },
    });

    const searchData = res.data.datas;
    // JS 사용 시 같은 값을 가리키면 같은 주소를 공유하기 때문에 JSON.stringify 활용하여 다른 주소로 값을 만든 후 변환
    const graphDataJson = JSON.stringify(res.data.datas);
    const graphData = JSON.parse(graphDataJson);

    _(graphData).each((data) => {
      const updateTime = `${dayjs().format(data.UT.substr(0, 19))}`;
      const utcOffset = dayjs(updateTime).utcOffset() * 1000 * 60;
      const utcTime = dayjs(updateTime).toDate().getTime() + utcOffset;
      data.T = utcTime;

      // const updateTime = `${dayjs().format(data.UT.substr(0, 19))}`;
      // const utcOffset = dayjs(updateTime).utcOffset() * 1000 * 60;
      // const utcTime = dayjs(updateTime).toDate().getTime() + utcOffset;
    });

    _(searchData).each((data) => {
      if (data.DP === 1) {
        data.DP = "Close";
      }
      if (data.DP === 2) {
        data.DP = "Open";
      }
      if (data.LP === 1) {
        data.LP = "UP";
      }
      if (data.LP === 2) {
        data.LP = "DOWN";
      }

      data.LSO = data.LSO === 0 ? "OFF" : "ON";
      // UTC 시간
      const updateTime = `${dayjs().format(data.UT.substr(0, 19))}`;
      const utcOffset = dayjs(updateTime).utcOffset() * 1000 * 60;
      const utcTime = dayjs(updateTime).toDate().getTime() + utcOffset;
      const clientUtcTime = dayjs(utcTime).format("YYYY-MM-DD HH:mm:ss");
      data.UT = clientUtcTime;

      data.ST = data.ST === 1 ? "Normal" : "Error";
      data.CS = data.CS === 0 ? "stop" : "run";
      data.MS = data.MS === 0 ? "OFF" : "ON";
      data.T = utcTime;

      if (data.RT !== 0) {
        let dryTime = "";
        const time = data.RT;
        const hour = Math.floor(time / 60);
        const minute = time % 60;
        if (hour > 9) {
          if (minute > 9) {
            dryTime = `${String(hour)} : ${String(minute)}`;
          } else {
            dryTime = `${String(hour)} : 0${String(minute)}`;
          }
          data.RT = dryTime;
        } else {
          if (minute > 9) {
            dryTime = `0${String(hour)} : ${String(minute)}`;
          } else {
            dryTime = `0${String(hour)} : 0${String(minute)}`;
          }
          data.RT = dryTime;
        }
      }
      if (data.RT === 0) {
        data.RT = `00 : 00`;
      }
      if (0 <= data.MD && data.MD <= 20) {
        if (data.OS === 1) {
          data.OS = "Operation Init";
        } else if (data.OS === 2) {
          data.OS = "Operation Ready";
        } else if (data.OS === 3) {
          data.OS = "Dry Run Check";
        } else if (data.OS === 4 || data.OS === 5) {
          data.OS = "Dry Run";
        } else if (data.OS === 6) {
          data.OS = "Cooling Down";
        } else if (data.OS === 7) {
          data.OS = "Dry Run Done";
        } else if (data.OS === 8) {
          data.OS = "Discharge Ready";
        } else if (data.OS === 9) {
          data.OS = "Discharge Run Check";
        } else if (data.OS === 10) {
          data.OS = "Discharge Run";
        } else if (data.OS === 11) {
          data.OS = "Discharge Run Done";
        } else if (data.OS === 12) {
          data.OS = "Operation Done";
        } else if (data.OS === 20) {
          data.OS = "Error";
        } else {
          console.log(`현재 Operation_Step은 ${data.OS}입니다. 추가해합니다.`);
        }
      }
      // 추가본(data.MD > 20일 때(RFID 장비일때))
      else if (data.MD >= 21) {
        if (
          data.OS === 1 ||
          data.OS === 2 ||
          data.OS === 3 ||
          data.OS === 4 ||
          data.OS === 5
        ) {
          data.OS = "Operation Init";
        } else if (data.OS === 6 || data.OS === 7) {
          data.OS = "Card Check";
        } else if (
          data.OS === 8 ||
          data.OS === 9 ||
          data.OS === 10 ||
          data.OS === 11 ||
          data.OS === 12 ||
          data.OS === 13
        ) {
          data.OS = "Weight Check";
        } else if (data.OS === 14) {
          data.OS = "Operation Ready";
        } else if (data.OS === 15 || data.OS === 16) {
          data.OS = "Dry Run Check";
        } else if (data.OS === 17 || data.OS === 18) {
          data.OS = "Dry Run";
        } else if (data.OS === 19) {
          data.OS = "Cooling Down";
        } else if (data.OS === 20) {
          data.OS = "Dry Run Done";
        } else if (data.OS === 21) {
          data.OS = "Discharge Ready";
        } else if (data.OS === 22) {
          data.OS = "Discharge Run Check";
        } else if (data.OS === 23) {
          data.OS = "Discharge Run";
        } else if (data.OS === 24) {
          data.OS = "Discharge Run Done";
        } else if (data.OS === 25) {
          data.OS = "Operation Done";
        } else if (data.OS === 40) {
          data.OS = "Error";
        } else {
          console.log(
            `현재 RFID Operation_Step은 ${data.OS}입니다. 추가해합니다.`
          );
        }
      } else {
        console.log("지금 ModelNum.", data.MD);
      }

      if (data.RCT !== 0) {
        let dryTime = "";
        const time = data.RCT;
        const hour = Math.floor(time / 60);
        const minute = time % 60;

        if (hour > 9) {
          if (minute > 9) {
            dryTime = `${String(hour)} : ${String(minute)}`;
          } else {
            dryTime = `${String(hour)} : 0${String(minute)}`;
          }
          data.RCT = dryTime;
        } else {
          if (minute > 9) {
            dryTime = `0${String(hour)} : ${String(minute)}`;
          } else {
            dryTime = `0${String(hour)} : 0${String(minute)}`;
          }
          data.RCT = dryTime;
        }
      }
      if (data.RCT === 0) {
        data.RCT = `00 : 00`;
      }

      data.EWO = data.EWO === 0 ? "Normal" : "Overload";
      data.EOB = data.EOB === 0 ? "None" : "Occurred";
      data.EAB = data.EAB === 0 ? "None" : "Occurred";
      data.EMO = data.EMO === 0 ? "None" : "Occurred";
      data.EBO = data.EBO === 0 ? "None" : "Occurred";
      data.EO1 = data.EO1 === 0 ? "None" : "Occurred";
      data.EA1 = data.EA1 === 0 ? "None" : "Occurred";
      data.EO2 = data.EO2 === 0 ? "None" : "Occurred";
      data.EA2 = data.EA2 === 0 ? "None" : "Occurred";
      data.ED = data.ED === 0 ? "None" : "Occurred";
      data.EE = data.EE === 0 ? "None" : "Occurred";
      data.ETO = data.ETO === 0 ? "None" : "Occurred";
      data.EL = data.EL === 0 ? "None" : "Occurred";

      if (data.LS === 0) {
        data.LS = "Lift Init";
      }
      if (data.LS === 1) {
        data.LS = "Lift Up Ready";
      }
      if (data.LS === 2) {
        data.LS = "Door Open Wait";
      }
      if (data.LS === 3) {
        data.LS = "Door Open";
      }
      if (data.LS === 4) {
        data.LS = "Door Open Done";
      }
      if (data.LS === 5) {
        data.LS = "Lift Up";
      }
      if (data.LS === 6) {
        data.LS = "Lift Up Done";
      }
      if (data.LS === 7) {
        data.LS = "Lift Down Ready";
      }
      if (data.LS === 8) {
        data.LS = "Lift Down Wait";
      }
      if (data.LS === 9) {
        data.LS = "Lift Down";
      }
      if (data.LS === 10) {
        data.LS = "Lift Down Done";
      }
      if (data.LS === 11) {
        data.LS = "Door Close";
      }
      if (data.LS === 12) {
        data.LS = "Door Close Wait";
      }
      if (data.LS === 13) {
        data.LS = "Door Close Done";
      }
      if (data.LS === 14) {
        data.LS = "Waste Mix";
      }
      if (data.LS === 15) {
        data.LS = "Lift Operation Done";
      }
    });

    const datas = { searchData: searchData, graphData: graphData };

    return datas;
  } catch (err) {
    console.log(err);
  }
};

export const faultAxios = async (mac: string, start: string, end: string) => {
  try {
    const res = await userAxios.get("data/fault", {
      params: {
        mac_addr: mac,
        start_time: start,
        end_time: end,
      },
    });
    const faultData = res.data.datas;

    _(faultData).each((data) => {
      const updateTime = `${dayjs().format(data.update_time.substr(0, 19))}`;
      const utcOffset = dayjs(updateTime).utcOffset() * 1000 * 60;
      const utcTime = dayjs(updateTime).toDate().getTime() + utcOffset;
      const clientUtcTime = dayjs(utcTime).format("YYYY-MM-DD HH:mm:ss");
      // const clientUtcTime = dayjs(updateTime).format("YYYY-MM-DD HH:mm:ss");
      data.update_time = clientUtcTime;
      // 원래 if(data.error_code === 1)만 존재함 나머진 추가
      // 에러코드 Number를 String형식으로 멮핑 후 저장
      if (data.error_code === 1) {
        data.error_code = "Over Weight";
        // data.error_code = 1;
      } else if (data.error_code === 2) {
        data.error_code = "Bi Metal Oil";
      } else if (data.error_code === 3) {
        data.error_code = "Bi Metal Air";
      } else if (data.error_code === 4) {
        data.error_code = "Motor Fault";
      } else if (data.error_code === 5) {
        data.error_code = "Blower Pump Fan Fault";
      } else if (data.error_code === 6) {
        data.error_code = "Tank Temp Over";
      } else if (data.error_code === 7) {
        data.error_code = "Oil 1 Temp Over";
      } else if (data.error_code === 8) {
        data.error_code = "Oil 2 Temp Over";
      } else if (data.error_code === 9) {
        data.error_code = "Air 1 Temp Over";
      } else if (data.error_code === 10) {
        data.error_code = "Air 2 Temp Over";
      } else if (data.error_code === 11) {
        data.error_code = "Discharge Temp Over";
      } else if (data.error_code === 12) {
        data.error_code = "Emergency Stop";
      } else if (data.error_code === 13) {
        data.error_code = "Lift Hydric Motor";
      } else if (data.error_code === 14) {
        data.error_code = "InLet Door 1 Movement Error";
      } else if (data.error_code === 15) {
        data.error_code = "InLet Door 2 Movement Error";
      } else if (data.error_code === 16) {
        data.error_code = "Run Button Pressed During Operation";
      } else if (data.error_code === 17) {
        data.error_code = "Inlet Door Open During Operation";
      } else if (data.error_code === 18) {
        data.error_code = "Main Swtich Turn On";
      } else if (data.error_code === 900) {
        data.error_code = "Communication Loss";
      } else {
        console.log("추가해야합니다.");
      }
    });
    return faultData;
  } catch (err) {
    console.log(err);
  }
};

export const dataGraphAxios = async (mac: string) => {
  try {
    const response = await userAxios.get("data/graph", {
      params: {
        mac_addr: mac,
      },
    });
    const graphData = response.data.datas;
    _(graphData).each((data) => {
      const updateTime = `${dayjs().format(data.T)}`;
      const utcOffset = dayjs(updateTime).utcOffset() * 1000 * 60;
      const utcTime = dayjs(updateTime).toDate().getTime() + utcOffset;
      data.T = utcTime;
    });
    return graphData;
  } catch (err) {
    console.log(err);
  }
};

// RFID Search
export const rfidAxios = async (
  mac: string,
  card: string,
  start: string,
  end: string
) => {
  try {
    const res = await userAxios.get("event/rfid_used", {
      params: {
        mac_addr: mac,
        card_id: card,
        start_time: start,
        end_time: end,
      },
    });

    const userStore = userUseStore();
    const rfidData = res.data.datas;
    // test start
    _(rfidData).each((data) => {
      // UTC 시간
      // const updateTime = `${dayjs().format(data.UT.substr(0, 19))}`;
      const updateTime = `${dayjs().format(data.UT.substr(0, 19))}`;
      const utcOffset = dayjs(updateTime).utcOffset() * 1000 * 60;
      const utcTime = dayjs(updateTime).toDate().getTime() + utcOffset;
      // const updateTime = `${dayjs().format(data.update_time.substr(0, 19))}`;
      // const utcOffset = dayjs(updateTime).utcOffset() * 1000 * 60;
      // const utcTime = dayjs(updateTime).toDate().getTime() + utcOffset;
      // const clientUtcTime = dayjs(utcTime).format("YYYY-MM-DD HH:mm:ss");
      // data.UT = clientUtcTime;
      data.T = utcTime;
    });

    // test end
    userStore.rfidData = rfidData;

    // JS 사용 시 같은 값을 가리키면 같은 주소를 공유하기 때문에 JSON.stringify 활용하여 다른 주소로 값을 만든 후 변환
    const rfidDataJson = JSON.stringify(res.data.datas);
    const rfidData2 = JSON.parse(rfidDataJson);

    return rfidData;
  } catch (err) {
    console.log(err);
  }
};

// ESG Search
export const esgAxios = async (mac: string, start: string, end: string) => {
  try {
    const res = await userAxios.get("event/esg", {
      params: {
        mac_addr: mac,
        start_time: start,
        end_time: end,
      },
    });

    const userStore = userUseStore();
    const esgData = res.data.datas;
    const sortTime = ref();
    _(esgData).each((data) => {
      const updateTime = `${dayjs().format(data.UT.substr(0, 19))}`;
      const utcOffset = dayjs(updateTime).utcOffset() * 1000 * 60;
      const utcTime = dayjs(updateTime).toDate().getTime() + utcOffset;
      data.T = utcTime;
    });

    userStore.esgData = esgData;

    // JS 사용 시 같은 값을 가리키면 같은 주소를 공유하기 때문에 JSON.stringify 활용하여 다른 주소로 값을 만든 후 변환
    const esgDataJson = JSON.stringify(res.data.datas);
    const esgData2 = JSON.parse(esgDataJson);

    return esgData;
  } catch (err) {
    console.log(err);
  }
};
