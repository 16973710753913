export const en = {
  switch: "Switch On/Off",
  cycle_status: "Cycle Status",
  cycle_count: "Cycle Count",
  dry_time: "Dry Time",
  cooling_time: "Cooling Time",
  discharge_time: "Discharge Time",
  weight: "Weight",
  energy: "Energy",
  power: "Power",
  temperature: "Temperature",
  total_power: "Total Power",
  loading: "Loading...",
  add: "Add",
  edit: "Edit",
  machine_list: "Machine List",
  machine_create: "Machine Create",
  group_create: "Group Create",
  user_create: "User Create",
  save: "Save",
  delete: "Delete",
  group_list: "Group List",
  user_list: "User List",
  password_edit: "Password Edit",
  add_device: "Add Device...",
  search: "Search",
  group_insert: "Group Insert",
  user_insert: "User Insert",
  start_time: "Start Time",
  end_time: "End Time",
  use: "Use",
  un_use: "Un Use",
  normal: "NORMAL",
  error: "ERROR",
  warning: "WARNING",
  heater_temperature: "Heater Temperature",
  tank_temperature: "Tank Temperature",
  active_electric: "Active Electric",
  active_energy: "Active Energy",
  active_power: "Active Power",
  settings_machine_info: "Setting Machine Information",
  site: "Site",
  group: "Group",
  user: "User",
  model: "Model",
  lon: "Longitude",
  lat: "Latitude",
  set_dry_run_time: "Set Dry Run Time",
  set_discharge_run_time: "Set Discharge Run Time",
  set_cooling_time: "Set Cooling Time",
  set_oil_heater_1_target_temperature: "Set Oil Heater 1 Target Temperature",
  set_oil_heater_2_target_temperature: "Set Oil Heater 2 Target Temperature",
  set_tank_over_heating_detect_temperature:
    "Set Tank Over Heating Detect Temperature",
  set_air_heater_1_target_temperature: "Set Air Heater 1 Target Temperature",
  set_air_heater_2_target_temperature: "Set Air Heater 2 Target Temperature",
  set_discharge_heater_target_temperature:
    "Set Discharge Heater Target Temperature",
  first: "First",
  last: "Last",
  error_input_weight_overload: "Error Input Weight Overload",
  error_oil_bi_metal: "Error Oil By Metal",
  error_air_bi_metal: "Error Air By Metal",
  error_motor_overload: "Error Motor Overload",
  error_blower_pump_fan_overload: "Error Blower Pump Fan Overload",
  error_tank_temperature_over_heating: "Error Tank Temperature Over Heating",
  error_oil_heater_1_over_heating: "Error Oil Heater 1 Over Heating",
  error_oil_heater_2_over_heating: "Error Oil Heater 2 Over Heating",
  error_air_heater_1_over_heating: "Error Air Heater 1 Over Heating",
  error_air_heater_2_over_heating: "Error Air Heater 1 Over Heating",
  error_discharge_heater_over_heating: "Error Discharge Heater Over Heating",
  error_emergency_stop: "Error Emergency Stop",
  error_lift_hydric_motor: "Error Lift Hydric Motor",
  error_in_let_one_movement_error: "Error InLet Door 1 Movement",
  error_in_let_two_movement_error: "Error InLet Door 2 Movement",
  error_run_button_pressed_during_operation: "Error Run Button Pressed During",
  error_inlet_door_open_during_operation: "Error Inlet Door Open During",
  error_main_switch_turn_on: "Error Main Switch Turn ON",
  connect_loss: "Connect Loss",
  machine_data: "Device Search",
  fault_data: "Fault Search",
  manager_create: "Manager Create",
  manager_list: "Manager List",
  manager_insert: "Manger Insert",
  manager: "Manager",
  user_edit: "User Edit",
  alive_count: "Alive Count",
  // 장비 검색 및 카드태깅 추가
  // 수정 전 데이터 모음
  // total_weight: "Waste Weight(Total)", // 장비의 전체 무게
  // average_weight: "Waste Weight(Average)",
  // total_weight: "Input Weight(Total)", // 장비의 전체 무게
  // total_energy: "Energy Consumption(Total)",
  // average_dry_weight: "Dry Weight(Average)",
  // 수정 전 데이터 모음
  total_weight: "Input (Total)", // 장비의 전체 무게
  average_weight: "Input (Average)",
  total_dry_weight: "Output (Total)",
  average_dry_weight: "Output (Average)",
  total_energy: "Power (Total)",
  average_energy: "Power (Average)",
  card_search: "Card Search",
  esg_search: "ESG Search",
  total_dry_time: "Drying Time(Total)",
  average_dry_time: "Drying Time(Average)",
  input_count: "Input(Count)",
  operation_count: "Operation(Count)",
};

export type i18Type = typeof en;
