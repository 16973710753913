export const ko = {
  switch: "스위치 On/Off",
  cycle_status: "사이클 상태",
  cycle_count: "사이클 횟수",
  dry_time: "건조 시간",
  cooling_time: "냉각 시간",
  discharge_time: "배출 시간",
  weight: "무게",
  energy: "전력",
  power: "전력량",
  temperature: "온도",
  total_power: "총 전력량",
  loading: "로딩 중...",
  add: "추가",
  edit: "수정",
  machine_list: "장비 목록",
  machine_create: "장비 생성",
  group_create: "그룹 생성",
  user_create: "사용자 생성",
  save: "저장",
  delete: "삭제",
  group_list: "그룹 목록",
  user_list: "사용자 목록",
  password_edit: "비밀번호 수정",
  add_device: "장비 추가...",
  search: "조회",
  group_insert: "그룹 추가",
  user_insert: "사용자 추가",
  start_time: "시작 시간",
  end_time: "종료 시간",
  use: "사용",
  un_use: "미사용",
  normal: "정상",
  error: "오류",
  warning: "경고",
  heater_temperature: "열전기 온도",
  tank_temperature: "탱크 온도",
  active_electric: "활성 전력",
  active_energy: "활성 전력",
  active_power: "활성 전력량",
  settings_machine_info: "설정 장비 정보",
  site: "장소",
  group: "회사",
  user: "사용자",
  model: "모델",
  lon: "경도",
  lat: "위도",
  set_dry_run_time: "건조 시간 설정",
  set_discharge_run_time: "배출 시간 설정",
  set_cooling_time: "냉각 시간 설정",
  set_oil_heater_1_target_temperature: "열전기 1 온도 설정",
  set_oil_heater_2_target_temperature: "열전기 2 온도 설정",
  set_tank_over_heating_detect_temperature: "탱크 온도 초과 감지 온도 설정",
  set_air_heater_1_target_temperature: "에어 1 온도 설정",
  set_air_heater_2_target_temperature: "에어 2 온도 설정",
  set_discharge_heater_target_temperature: "배출 열전기 온도 설정",
  first: "시작",
  last: "끝",
  error_input_weight_overload: "입력 무게 초과",
  error_oil_bi_metal: "오일 오류",
  error_air_bi_metal: "에어 오류",
  error_motor_overload: "모터 과부하",
  error_blower_pump_fan_overload: "송풍기 펌프 팬 과부하",
  error_tank_temperature_over_heating: "탱크 온도 과열",
  error_oil_heater_1_over_heating: "오일 히터 1 과부하",
  error_oil_heater_2_over_heating: "오일 히터 2 과부하",
  error_air_heater_1_over_heating: "에어 히터 1 과부하",
  error_air_heater_2_over_heating: "에어 히터 1 과부하",
  error_discharge_heater_over_heating: "배출 히터 과부하",
  error_lift_hydric_motor: "유압 모터 과부하",
  error_emergency_stop: "비상정지",
  error_in_let_one_movement_error: "투입구 1 문 동작 에러",
  error_in_let_two_movement_error: "투입구 2 문 동작 에러",
  error_run_button_pressed_during_operation: "작동중 런 버튼 눌림 에러",
  error_inlet_door_open_during_operation: "작동 중 입구 열림 에러",
  error_main_switch_turn_on: "메인 스위치 켜짐 에러",
  connect_loss: "연결 손실",
  machine_data: "장비 조회",
  fault_data: "오류 조회",
  manager_create: "매니저 생성",
  manager_list: "매니저 목록",
  manager_insert: "매니저 추가",
  manager: "매니저",
  user_edit: "사용자 수정",
  alive_count: "동작 시간",
  // 장비 검색 및 카드태깅 추가
  // total_weight: "쓰레기 무게(전체)",
  // average_weight: "쓰레기 무게(평균)",
  total_weight: "투입 무게(전체)",
  average_weight: "투입 무게(평균)",
  total_dry_weight: "건조 후 무게(전체)",
  average_dry_weight: "건조 후 무게(평균)",
  total_energy: "에너지 소비(전체)",
  average_energy: "에너지 소비(평균)",
  card_search: "카드 조회",
  esg_search: "ESG 조회",
  total_dry_time: "건조 시간(전체)",
  average_dry_time: "건조 시간(평균)",
  input_count: "투입(횟수)",
  operation_count: "가동(횟수)",
};

export type i18Type = typeof ko;
