import { defineStore } from 'pinia'

export const superUseStore = defineStore('super', {
	state: () => ({}),
	actions: {
		superPass() {
			if (sessionStorage.getItem('permission') === 'super') return true
			else return false
		},
	},
})
