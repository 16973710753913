import axios from "axios";
import _ from "lodash";
import { adminUseStore } from "@/store/admin";
import { userUseStore } from "@/store/user";
import { headerClear, tokenRefresh } from "./account.service";
import { deleteCookie, getCookie } from "./local/cookies.service";
import router from "@/router";
import { ROU_NAME } from "@/router/router.name";

export const adminAxios = axios.create({ baseURL: "api/", timeout: 5000 });

//요청 후
adminAxios.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    if (
      err.response.status === 401 &&
      err.response.data.detail === "Token changed"
    ) {
      sessionStorage.clear();
      deleteCookie("access_T");
      deleteCookie("refresh_T");
      headerClear();
      alert("Duplicate login");
      router.push({ name: ROU_NAME.LOGIN });
    } else if (
      err.response.status === 401 &&
      err.response.data.detail === "Missing Authorization Header"
    ) {
      await tokenRefresh();
      return await adminAxios.request(err.config);
    } else if (
      err.response.status === 422 &&
      err.response.data.detail === "Signature has expired"
    ) {
      err.defaults.headers.common["Authorization"] = `Bearer ${getCookie(
        "access_T"
      )}`;
      await tokenRefresh();
      return await adminAxios.request(err.config);
    } else {
      return Promise.reject(err);
    }
  }
);

//해당 admin 그룹 유저 리스트 갱신
export const userListAxios = async (group: string) => {
  const adminStore = adminUseStore();
  try {
    const res = await adminAxios.get("account/list");

    const responseData = res.data.user_list;
    if (group === "super") {
      const userFilter = _.filter(
        responseData,
        (list) => list.permission === "user"
      );
      const userList = userFilter.map((list: any) => {
        const user = list.username;
        return user;
      });
      adminStore.userList = userList;
      console.log(`${group} User List OK`);
    } else {
      const userFilter = _.filter(
        responseData,
        (list) => list.group === group && list.permission === "user"
      );
      const userList = userFilter.map((list: any) => {
        const user = list.username;
        return user;
      });
      adminStore.userList = userList;
      console.log(`${group} User List OK`);
    }
  } catch (err: any) {
    console.log(`${group} User List Fail : `, err);
  }
};

//해당 admin 그룹 장비 리스트 갱신
export const machineTableAxios = async (group: string) => {
  try {
    const userStore = userUseStore();

    const res = await adminAxios.get("device/list");

    const device_list = res.data.device_list;
    if (group === "super") {
      userStore.deviceList = device_list.map((list: any, idx: number) => {
        return {
          no: idx + 1,
          group: list.group,
          sub_group: list.sub_group,
          mac_addr: list.mac_addr,
          user_name: list.username,
          site_name: list.sitename,
          lon: list.lon,
          lat: list.let,
          use: list.use,
        };
      });
      return;
    }
    const groupMatch = _.filter(device_list, (list) => list.group === group);
    userStore.deviceList = groupMatch.map((group: any, idx: number) => {
      return {
        no: idx + 1,
        group: group.group,
        sub_group: group.sub_group,
        mac_addr: group.mac_addr,
        user_name: group.username,
        site_name: group.sitename,
        lon: group.lon,
        lat: group.let,
        use: group.use,
      };
    });

    console.log(`${group} Machine List OK`);
  } catch (err: any) {
    console.log(`${group} Machine List FAIL : `, err);
  }
};

//장비 추가
export const machineAddAxios = async (
  macAddress: string,
  userName: string,
  group: string,
  manager: string,
  siteName: string,
  lon: string,
  lat: string,
  use: boolean
) => {
  try {
    await adminAxios.get("device/insert", {
      params: {
        mac_addr: macAddress,
        username: userName,
        group: group,
        sub_group: manager,
        sitename: siteName,
        lon: lon,
        let: lat,
        use: use,
      },
    });
  } catch (err: any) {
    console.log(`MAC FAIL : ${macAddress} : ${err}`);
  }
};

//장비 셋팅
export const machineSettingAxios = async (
  userName: string,
  siteName: string,
  // adminGroup: any,
  adminGroup: string,
  // group: string,
  manager: string,
  macAddress: string,
  lon: string,
  lat: string,
  use: boolean
) => {
  try {
    await adminAxios.get("device/update", {
      params: {
        username: userName,
        sitename: siteName,
        group: adminGroup,
        // group: group,
        sub_group: manager,
        mac_addr: macAddress,
        lon: lon,
        let: lat,
        use: use,
      },
    });
    // 실행 안되는 부분
  } catch (err: any) {
    console.log(`Update FAIL : ${macAddress} : ${err}`);
  }
};

//장비 삭제
export const deleteMachineAxios = async (mac: string) => {
  try {
    await adminAxios.get("device/delete", { params: { mac_addr: mac } });
  } catch (err: any) {
    console.log(`Delete FAIL : ${mac} : ${err}`);
  }
};
