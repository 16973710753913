import { defineStore } from 'pinia'

export const adminUseStore = defineStore('admin', {
	state: () => ({
		userList: [] as any[],
	}),
	actions: {
		adminPass() {
			if (sessionStorage.getItem('permission') === 'admin') return true
			else return false
		},
	},
})
