import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { ROU_NAME } from "./router.name";

import LoginVue from "../views/LoginPage.vue";

import UserVue from "@/views/user/IndexPage.vue";
import DashBoardVue from "@/views/user/DashboardPage.vue";
import DetailVue from "@/views/user/DetailPage.vue";
import SearchVue from "@/views/user/SearchPage.vue";
import AccountVue from "@/views/user/AccountPage.vue";

import AdminVue from "@/views/admin/IndexPage.vue";
import AdminPageVue from "@/views/admin/AdminPage.vue";
import SettingVue from "@/views/admin/SettingPage.vue";

import SuperVue from "@/views/super/IndexPage.vue";
import SuperPageVue from "@/views/super/SuperPage.vue";

// import DashBoardCamVue from "@/views/user/DashboardPageCam.vue";

import { userUseStore } from "@/store/user";
import { adminUseStore } from "@/store/admin";
import { superUseStore } from "@/store/super";

//페이지 로딩될때 세션에있는 정보로 페이지 인증 시킴
const requireAuthAll = () => (to: any, from: any, next: any) => {
  const userStore = userUseStore();
  if (userStore.authPass()) return next();
  else return router.push({ name: ROU_NAME.LOGIN });
};

const requireAuthAdmin = () => (to: any, from: any, next: any) => {
  const adminStore = adminUseStore();
  const superStore = superUseStore();
  if (adminStore.adminPass() || superStore.superPass()) return next();
  else return router.push({ name: ROU_NAME.DASHBOARD });
};

const requireAuthSuper = () => (to: any, from: any, next: any) => {
  const superStore = superUseStore();
  if (superStore.superPass()) return next();
  else return router.push({ name: ROU_NAME.DASHBOARD });
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: ROU_NAME.LOGIN,
    component: LoginVue,
  },
  //유저 페이지
  {
    path: "/user",
    name: ROU_NAME.USER,
    component: UserVue,
    // 컴포넌트가 화면에 표시되기 전에 수행될 로직
    beforeEnter: requireAuthAll(),
    children: [
      {
        path: "/dashboard",
        name: ROU_NAME.DASHBOARD,
        component: DashBoardVue,
      },
      {
        path: "/detail",
        name: ROU_NAME.DETAIL,
        component: DetailVue,
        props: true,
      },
      {
        path: "/search",
        name: ROU_NAME.SEARCH,
        component: SearchVue,
        props: true,
      },
      {
        path: "/mypage",
        name: ROU_NAME.ACCOUNT,
        component: AccountVue,
      },
    ],
  },
  //관리자 페이지
  {
    path: "/admin",
    name: ROU_NAME.ADMIN,
    component: AdminVue,
    beforeEnter: requireAuthAdmin(),
    children: [
      {
        path: "/adminmain",
        name: ROU_NAME.ADMINPAGE,
        component: AdminPageVue,
      },
      {
        path: "/setting",
        name: ROU_NAME.SETTING,
        component: SettingVue,
        props: true,
      },
    ],
  },
  //최고 관리자 페이지
  {
    path: "/super",
    name: ROU_NAME.SUPER,
    component: SuperVue,
    beforeEnter: requireAuthSuper(),
    children: [
      {
        path: "/supermain",
        name: ROU_NAME.SUPERPAGE,
        component: SuperPageVue,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
  },
});
router.beforeEach((to, from, next) => {
  const userStore = userUseStore();
  if (to.name !== ROU_NAME.LOGIN && !userStore.authPass())
    next({ name: ROU_NAME.LOGIN });
  else next();
});

export default router;
